const PAGES = {
    'accounting_data': {
        articles: {
            tags: true,
            url: true,
            active: true,
            article_number: true,
            bulky: true,
            deleted: true,
            depth: true,
            description: true,
            disposable_quantity: true,
            ean: true,
            eu_account: true,
            euvat_account: true,
            export_account: true,
            eu_vat_sale_account: true,
            sales_account_export: true,
            vat: true,
            height: true,
            housework: true,
            housework_type: true,
            manufacturer: true,
            manufacturer_article_number: true,
            note: true,
            purchase_account: true,
            purchase_price: true,
            quantity_in_stock: true,
            sales_price: true,
            sales_account: true,
            stock_goods: true,
            stock_value: true,
            supplier_name: true,
            supplier_number: true,
            type: true,
            unit: true,
            webshop_article: true,
            weight: true,
            width: true,
            cost_calculation_method: true,
            stock_account: true,
            stock_change_account: true,
            direct_cost: true,
            freight_cost: true,
            other_cost: true,
            default_stock_point: true,
            default_stock_location: true,
            segment: true,
            amount_sold: true,
            unit_sold: true,
            is_golf_article: true,
            article_id: true,
            description_english: true,
            gross_price: true,
            uses_moss: true,
            barcodes: true,
            article_account_coding_id: true,
        },
        contracts: {
            category: true,
            tags: true,
            url: true,
            url_tax_reduction_list: true,
            active: true,
            administration_fee: true,
            basis_tax_reduction: true,
            comments: true,
            continuous: true,
            contract_date: true,
            contract_length: true,
            contribution_percent: true,
            contribution_value: true,
            cost_center: true,
            currency: true,
            customer_name: true,
            customer_number: true,
            customer: true,
            deleted: true,
            document_number: true,
            external_invoice_reference1: true,
            external_invoice_reference2: true,
            freight: true,
            gross: true,
            house_work: true,
            invoice_discount: true,
            invoice_interval: true,
            invoices_remaining: true,
            language: true,
            last_invoice_date: true,
            net: true,
            our_reference: true,
            period_end: true,
            period_start: true,
            price_list: true,
            print_template: true,
            project: true,
            remarks: true,
            round_off: true,
            status: true,
            tax_reduction: true,
            tax_reduction_type: true,
            template_name: true,
            template_number: true,
            terms_of_delivery: true,
            terms_of_payment: true,
            total: true,
            total_to_pay: true,
            total_vat: true,
            vat_included: true,
            way_of_delivery: true,
            your_order_number: true,
            your_reference: true,
            exclude_from_auto_renewal: true,
            upcoming: true,
        },
        invoices: {
            url: true,
            url_tax_reduction_list: true,
            accounting_method: true,
            address1: true,
            address2: true,
            administration_fee: true,
            administration_fee_vat: true,
            balance: true,
            fully_paid: true,
            basis_tax_reduction: true,
            booked: true,
            cancelled: true,
            city: true,
            comments: true,
            contract_reference: true,
            contribution_percent: true,
            contribution_value: true,
            cost_center: true,
            country: true,
            credit: true,
            credit_invoice_reference: true,
            currency: true,
            currency_rate: true,
            currency_unit: true,
            customer: true,
            customer_name: true,
            customer_number: true,
            delivery_address1: true,
            delivery_address2: true,
            delivery_city: true,
            delivery_country: true,
            delivery_date: true,
            delivery_name: true,
            delivery_zip_code: true,
            document_number: true,
            due_date: true,
            eu_quarterly_report: true,
            external_invoice_reference1: true,
            external_invoice_reference2: true,
            final_pay_date: true,
            freight: true,
            freight_vat: true,
            gross: true,
            house_work: true,
            invoice_date: true,
            invoice_period_end: true,
            invoice_period_start: true,
            invoice_reference: true,
            invoice_type: true,
            language: true,
            last_remind_date: true,
            net: true,
            not_completed: true,
            nox_finans: true,
            ocr: true,
            offer_reference: true,
            order_reference: true,
            organisation_number: true,
            our_reference: true,
            outbound_date: true,
            payment_way: true,
            phone1: true,
            phone2: true,
            price_list: true,
            print_template: true,
            project: true,
            remarks: true,
            reminders: true,
            round_off: true,
            sent: true,
            tax_reduction: true,
            tax_reduction_type: true,
            terms_of_delivery: true,
            terms_of_payment: true,
            time_basis_reference: true,
            total: true,
            total_to_pay: true,
            total_vat: true,
            vat_included: true,
            voucher_number: true,
            voucher_series: true,
            voucher_year: true,
            warehouse_ready: true,
            way_of_delivery: true,
            your_order_number: true,
            your_reference: true,
            zip_code: true,
            failed_to_pause: true,
            file: true,
            receipt: true,
            golf_invoice: true,
            receipt_sent: true,
            failed_to_send_receipt: true,
            receipt_sending_failure_reason: true,
            receipt_sent_on: true,
            reminder_sent: true,
            failed_to_send_reminder: true,
            reminder_sending_failure_reason: true,
            reminder_sent_on: true,
            visma_invoice_id: true,
            created_by_user_id: true,
            total_amount_invoice_currency: true,
            total_vat_amount_invoice_currency: true,
            set_off_amount_invoice_currency: true,
            _customer_id: true,
            edi_service_deliver_id: true,
            customer_is_private_person: true,
            customer_email: true,
            payment_reference_number: true,
            remaining_amount_invoice_currency: true,
            created_from_draft_id: true,
            payment_status: true,


        },
        customers: {
            tags: true,
            url: true,
            active: true,
            address1: true,
            address2: true,
            city: true,
            comments: true,
            cost_center: true,
            country: true,
            country_code: true,
            currency: true,
            customer_number: true,
            deleted: true,
            delivery_address1: true,
            delivery_address2: true,
            delivery_city: true,
            delivery_country: true,
            delivery_country_code: true,
            delivery_fax: true,
            delivery_name: true,
            delivery_phone1: true,
            delivery_phone2: true,
            delivery_zip_code: true,
            email: true,
            email_invoice: true,
            email_invoice_bcc: true,
            email_invoice_cc: true,
            email_offer: true,
            email_offer_bcc: true,
            email_offer_cc: true,
            email_order: true,
            email_order_bcc: true,
            email_order_cc: true,
            external_reference: true,
            fax: true,
            gln: true,
            gln_delivery: true,
            invoice_administration_fee: true,
            invoice_discount: true,
            invoice_freight: true,
            invoice_remark: true,
            name: true,
            organisation_number: true,
            our_reference: true,
            phone1: true,
            phone2: true,
            price_list: true,
            project: true,
            sales_account: true,
            show_price_vat_included: true,
            terms_of_delivery: true,
            terms_of_payment: true,
            type: true,
            vat_number: true,
            vat_type: true,
            visiting_address: true,
            visiting_city: true,
            visiting_country: true,
            visiting_country_code: true,
            visiting_zip_code: true,
            www: true,
            way_of_delivery: true,
            your_reference: true,
            zip_code: true,
            segment: true,
            total_billed: true,
            late_pay_ratio: true,
            billed_since: true,
            golf_member: true,
            customer_id: true,
            delivery_method_id: true,
            pay_to_account_id: true,
            webshop_customer_number: true,
            last_invoice_date: true,
            is_northern_ireland: true,
            force_bookkeep_vat: true,
            is_future_invoice_date_allowed: true,
            order: true,
            offer: true,
            invoice: true,

        },
        statistics: {},
    },
    'golf_related': {
        golfRelatedInvoicePayment: {},
        members: {
            active: true,
            accounting_customer: true,
            golf_id: true,
            first_name: true,
            last_name: true,
            sex: true,
            age: true,
            home_club: true,
            member_number: true,
            HCP: true,
            player_category: true,
            member_category: true,
            fee_rule: true,
            signup_date: true,
            home_street_address: true,
            zip_code: true,
            post_street_address: true,
            country: true,
            city: true,
            home_phone_number: true,
            mobile_phone_number: true,
            work_mobile_phone_number: true,
            email: true,
            image: true,

        },
        exports: {
            generate_contract: false,
            generate_invoice: false,
            file_type: false,
            handled: false,
            finished_without_errors: false,
            file: false,
            file_data: false,
            file_name: false,
            verbose_file_name: false,
            num_sync_attempts: false,
            event_id: false,
            invoice_text: false,
            terms_of_payment: false,
            invoice_date: false,
            contract_date: false,
            period_start_date: false,
            invoice_interval: false,
            contract_length: false,
            your_reference: false,
            our_reference: false,
            due_date: false,
            divide_amount_on_contract_length: false,
            invoice_fee: false,
            extra_invoice_row_text: false

        },
        receipts: {},
        fees: {
            name: true,
            description: true,

        },
        git: {
            contract_length: false,
            number_of_invoices_to_create: false,
            invoice_interval: false,
            divide_amount_on_contract_length: false,
            divide_amount_on_number_of_invoices: false,
            contract_date: false,
            period_start: false,
            invoice_text: false,
            extra_invoice_row_text: false,
            payment_terms: false,
            terms_of_payment: false,
            invoice_fee: false,
            due_date: false,
            invoice_date: false,
            your_reference: false,
            our_reference: false,
        }
    },
    'configuration': {
        email_configuration: {
            use_general_mail_server: true,
            mail_server: true,
            email_address: true,
            email_password: true,
            email_port: true,
            banner: true,
            reminder_subject: true,
            reminder_text: true,
            receipt_subject: true,
            receipt_text: true,
            auto_send_receipts: true,
            receipts_apply_to: true,
            auto_send_reminders: true,
            reminders_apply_to: true,
            reminders_days_after_duedate: true,
            logo: true,

        },
        member_configuration: {
            auto_update_member: true,
            set_default_delivery_method_to_email: true,
            exclude_dash_from_customer_number: true,
            terms_of_payment: true,

        },
        article_configuration: {
            custom_accounts: true,
            auto_set_article_number_and_sales_accounts: true,
            update_accounts_in_existing_articles: true,
            update_description_in_existing_articles: true,
            update_price_in_existing_articles: true,
            se_sale_account_25: true,
            se_sale_account_12: true,
            se_sale_account_6: true,
            se_sale_account_0: true,
            se_sale_account_default: true,
            eu_sale_account: true,
            eu_vat_sale_account: true,
            export_sale_account: true,
            article_pricelist: true,
            article_account_coding_default: true,
            article_account_coding_25: true,
            article_account_coding_12: true,
            article_account_coding_6: true,
            article_account_coding_0: true,
            use_file_account: true,
            unit: true,
            vat_code_25: true,
            vat_code_12: true,
            vat_code_6: true,
            vat_code_0: true,
            mapping_article_account_coding: true,
            mapping_se_sale_account: true,
            mapping_eu_sale_account: true,
            mapping_export_sale_account: true
        },
        invoice_configuration: {
            payment_rules: true,
            auto_pause_unpaid_and_overdue_invoices: true,
            pause_invoice_days_count: true,
            pause_invoice_days_after_due_date: true,
            customers_segment: true,
            terms_of_payment: true,
            your_reference: true,
            invoice_fee: true,
            our_reference: true,
            all_invoices: true,
            lost_revenue_days: true,
            receive_notification: true,
            receive_notification_days_after_due_date: true,
            last_modified: true,
            created: true,
            bookkeep_invoice: true,
            send_invoice: true,
            send_channel: true,
            send_method: true,
            invoice_text: true,
            create_invoice_with_default_account: true,
            default_sales_account: true,
            is_warehouse_user: true,
            always_use_receiver: true,
            use_gits_article_text: true,
        },
        contract_configuration: {
            auto_create_invoice: true,
            create_invoice_based_on: true,
            use_x_day_before_period_start: true,
            days_before_period_start: true,
            day_of_every_month: true,
            days_from_period_start: true,
            auto_bookkeep_created_invoices: true,
            auto_send_invoices: true,
            use_customer_default_send_method: true,
            send_invoice_with_fortnox_finance: true,
            send_all_invoices_through: true,
            use_print_service_if_no_email: true,
            use_print_service_for_print: true,
            send_failed_nox_as_reg: true,
            auto_renew_contracts: true,
            use_current_article_data: true,
            contracts_to_exclude_from_auto_renewal: true,
            todo_with_inactive_articles: true,
            auto_update_contract_prices: true,
            article_prices_are_ex_vat: true,
            using_period_allocations: true
        }
    },
    'help': {
        faq: {},
        support: {}
    },
    'member_care': {
        sms: {},
        email: {}
    }
}

export default PAGES