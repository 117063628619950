<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between align-items-center">
        <b-card-title class="mb-0">
          {{ $t(locale.createCustomer) }}
        </b-card-title>
        <LittleTabs :tabs="tabs" :switchTabs="switchTabs"/>
      </div>
    </b-card>
    <!-- <ValidationObserver ref="form">
        <KeepAlive>
            <transition name="zoom-fade" mode="out-in">
                <component :is="currentComponent" />
            </transition>
        </KeepAlive>
    </ValidationObserver> -->


    <div v-show="tabSwitch === 1">
      <b-row>
        <b-col cols="12" lg="12">
          <SecondaryCard :title="$t(locale.customerDetails)">
            <b-container fluid>
              <b-row>
                <b-col cols="12" lg="3" v-if="hidden_fields.customer_number">
                  <b-form-group
                      :label="$t(locale.customerNumber)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.customer_number"
                        :placeholder="$t(locale.customerNumber)"
                    />
                  </b-form-group>
                </b-col>

                <b-col v-if="hidden_fields.type">
                  <b-form-group
                      :label="$t(locale.customerType)"
                      label-for="input-1"
                  >
                    <LittleTabs
                        :tabs="[
                                            {
                                                label: 'CustomersCardEdit.company',
                                                active: true,
                                                value: 'COMPANY',
                                            },
                                            {
                                                label: 'CustomersCardEdit.private',
                                                active: false,
                                                value: 'PRIVATE',
                                            },
                                        ]"
                        buttonSize="sm"
                        containerStyle="justify-content-start"
                        btnClass="tabWrapperSmall2"
                        v-model="singleCustomer.type"
                        :defaultValue="true"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3" v-if="hidden_fields.organization_number">
                  <b-form-group
                      :label="$t(locale.organizationLabel)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.organisation_number"
                        :placeholder="$t(locale.organizationLabel)"
                    />
                  </b-form-group>
                </b-col>

                <b-col v-if="hidden_fields.active">
                  <b-form-group
                      :label="$t(locale.active)"
                      label-for="input-1"
                  >
                    <LittleTabs
                        :tabs="[
                                            {
                                                label: 'CustomersCardEdit.yes',
                                                active: true,
                                                value: true,
                                            },
                                            {
                                                label: 'CustomersCardEdit.no',
                                                active: false,
                                                value: false,
                                            },
                                        ]"
                        v-model="singleCustomer.active"
                        :defaultValue="true"
                        buttonSize="sm"
                        containerStyle="justify-content-start"
                        btnClass="tabWrapperSmall2"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3" v-if="hidden_fields.name">
                  <b-form-group
                      :label="$t(locale.name)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.name"
                        :placeholder="$t(locale.name)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3" v-if="hidden_fields.phone1">
                  <b-form-group
                      :label="$t(locale.phone1)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.phone1"
                        :placeholder="$t(locale.phone1)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3" v-if="hidden_fields.phone2">
                  <b-form-group
                      :label="$t(locale.phone2)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.phone2"
                        :placeholder="$t(locale.phone2)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3" v-if="hidden_fields.email">
                  <b-form-group
                      :label="$t(locale.email)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.email"
                        :placeholder="$t(locale.email)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3" v-if="hidden_fields.www">
                  <b-form-group
                      :label="$t(locale.url)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.www"
                        :placeholder="$t(locale.url)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3" v-if="hidden_fields.address1">
                  <b-form-group
                      :label="$t(locale.address1)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.address1"
                        :placeholder="$t(locale.address1)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3" v-if="hidden_fields.address2">
                  <b-form-group
                      :label="$t(locale.address2)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.address2"
                        :placeholder="$t(locale.address2)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3">
                  <b-form-group
                      :label="$t(locale.urlFirst)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        :placeholder="$t(locale.urlFirst)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3" v-if="hidden_fields.fax">
                  <b-form-group
                      :label="$t(locale.fax)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.fax"
                        :placeholder="$t(locale.fax)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3" v-if="hidden_fields.zip_code">
                  <b-row>
                    <b-col cols="12" lg="6">
                      <b-form-group
                          :label="$t(locale.zipCodeFirst)"
                          label-for="input-1"
                      >
                        <b-form-input
                            size="sm"
                            v-model="singleCustomer.zip_code"
                            :placeholder="$t(locale.zipCodeFirst)"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" lg="6">
                      <b-form-group
                          :label="$t(locale.cityFirst)"
                          label-for="input-1"
                      >
                        <b-form-input
                            size="sm"
                            v-model="singleCustomer.city"
                            :placeholder="$t(locale.cityFirst)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12" lg="3" v-if="hidden_fields.country">
                  <b-form-group
                      :label="$t(locale.country)"
                      label-for="input-1"
                  >
                    <v-select
                        :placeholder="$t(locale.country)"
                        :options="countries"
                        v-model="singleCustomer.country"
                        @option:selected="computeSelectCountry($event, 'country')"
                        class="resource-selector select-size-sm d-inline-block w-full"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-container>
          </SecondaryCard>
        </b-col>

        <b-col cols="12" lg="6">
          <SecondaryCard :title="$t(locale.deliverAddress)" class="mt-2">
            <b-container fluid>
              <b-row>
                <b-col cols="12" lg="6" v-if="hidden_fields.delivery_name">
                  <b-form-group
                      :label="$t(locale.name2)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.delivery_name"
                        :placeholder="$t(locale.name2)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="6" v-if="hidden_fields.delivery_phone1">
                  <b-form-group
                      :label="$t(locale.phoneSecond1)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.delivery_phone1"
                        :placeholder="$t(locale.phoneSecond1)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="6 " v-if="hidden_fields.delivery_phone2">
                  <b-form-group
                      :label="$t(locale.phoneSecond2)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.delivery_phone2"
                        :placeholder="$t(locale.phoneSecond2)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="6" v-if="hidden_fields.delivery_address1">
                  <b-form-group
                      :label="$t(locale.invoice1)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.delivery_address1"
                        :placeholder="$t(locale.invoice1)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="6" v-if="hidden_fields.delivery_address2">
                  <b-form-group
                      :label="$t(locale.invoice2)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.delivery_address2"
                        :placeholder="$t(locale.invoice2)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="6" v-if="hidden_fields.delivery_zip_code">
                  <b-row>
                    <b-col cols="12" lg="6">
                      <b-form-group
                          :label="$t(locale.zipCode)"
                          label-for="input-1"
                      >
                        <b-form-input
                            size="sm"
                            v-model="singleCustomer.delivery_zip_code"
                            :placeholder="$t(locale.zipCode)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <b-form-group
                          :label="$t(locale.city2)"
                          label-for="input-1"
                      >
                        <b-form-input
                            size="sm"
                            v-model="singleCustomer.delivery_city"
                            :placeholder="$t(locale.city2)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

              </b-row>
            </b-container>

          </SecondaryCard>
        </b-col>

        <b-col cols="12" lg="6">
          <SecondaryCard :title="$t(locale.visitingAddress)" class="mt-2">
            <b-container fluid>
              <b-row>
                <!-- <b-col cols="12" lg="6">
                    <b-form-group
                            :label="$t(locale.name3)"
                            label-for="input-1"
                        >
                        <b-form-input
                            size="sm"
                            v-model="singleCustomer."
                            :placeholder="$t(locale.name3)"
                        />
                    </b-form-group>
                </b-col> -->

                <!-- <b-col cols="12" lg="6">
                    <b-form-group
                            :label="$t(locale.phone13)"
                            label-for="input-1"
                        >
                        <b-form-input
                            size="sm"
                            v-model="singleCustomer."
                            :placeholder="$t(locale.phone13)"
                        />
                    </b-form-group>
                </b-col> -->

                <!-- <b-col cols="12" lg="6">
                    <b-form-group
                            :label="$t(locale.phone23)"
                            label-for="input-1"
                        >
                        <b-form-input
                            size="sm"
                            v-model="singleCustomer."
                            :placeholder="$t(locale.phone23)"
                        />
                    </b-form-group>
                </b-col> -->

                <b-col cols="12" lg="6" v-if="hidden_fields.visiting_address">
                  <b-form-group
                      :label="$t(locale.address13)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleCustomer.visiting_address"
                        :placeholder="$t(locale.address13)"
                    />
                  </b-form-group>
                </b-col>

                <!-- <b-col cols="12" lg="6">
                    <b-form-group
                            :label="$t(locale.address23)"
                            label-for="input-1"
                        >
                        <b-form-input
                            size="sm"
                            v-model="singleCustomer."
                            :placeholder="$t(locale.address23)"
                        />
                    </b-form-group>
                </b-col> -->

                <b-col cols="12" lg="6">
                  <b-row>
                    <b-col cols="12" lg="6" v-if="hidden_fields.visiting_zip_code">
                      <b-form-group
                          :label="$t(locale.zipCode3)"
                          label-for="input-1"
                      >
                        <b-form-input
                            size="sm"
                            v-model="singleCustomer.visiting_zip_code"
                            :placeholder="$t(locale.zipCode3)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" lg="6" v-if="hidden_fields.visiting_city">
                      <b-form-group
                          :label="$t(locale.city3)"
                          label-for="input-1"
                      >
                        <b-form-input
                            size="sm"
                            v-model="singleCustomer.visiting_city"
                            :placeholder="$t(locale.city3)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

              </b-row>
            </b-container>
          </SecondaryCard>
        </b-col>

        <b-col cols="12" lg="12">
          <SecondaryCard :title="$t(locale.memberDetails)" class="mt-2">
            <b-container fluid>
              <b-row>
                <!-- <b-col cols="12" lg="6">
                    <b-form-group
                            :label="$t(locale.sni)"
                            label-for="input-1"
                        >
                        <b-form-input
                            size="sm"
                            v-model="singleCustomer."
                            :placeholder="$t(locale.shippingCharge)"
                        />
                    </b-form-group>
                </b-col>

                <b-col cols="12" lg="6">
                    <b-form-group
                            :label="$t(locale.cfar)"
                            label-for="input-1"
                        >
                        <b-form-input
                            size="sm"
                            v-model="singleCustomer."
                            :placeholder="$t(locale.invoiceCharge)"
                        />
                    </b-form-group>
                </b-col>

                <b-col cols="12" lg="6">
                    <b-form-group
                            :label="$t(locale.shopId)"
                            label-for="input-1"
                        >
                        <b-form-input
                            size="sm"
                            v-model="singleCustomer."
                            :placeholder="$t(locale.invoiceDiscount)"
                        />
                    </b-form-group>
                </b-col> -->

                <b-col cols="12" lg="6" v-if="hidden_fields.comments">
                  <b-form-group
                      :label="$t(locale.notes)"
                      label-for="input-1"
                  >
                    <b-form-textarea
                        v-model="singleCustomer.comments"
                        size="lg"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
          </SecondaryCard>
        </b-col>
      </b-row>
    </div>
    <div v-show="tabSwitch === 2">
      <b-container fluid>
        <b-row>
          <b-col cols="12" lg="12">
            <SecondaryCard :title="$t(locale.invoiceDetails)">
              <b-container fluid>
                <b-row>
                  <b-col cols="12" lg="3" v-if="hidden_fields.terms_of_payment">
                    <p
                        class="h4 mb-2"
                        v-text="$t(locale.paymentLabel)"
                    />
                    <div class="flex flex-col">
                      <div>
                        <b-form-group
                            :label="$t(locale.paymentTerms)"
                            label-for="input-1"
                        >
                          <v-select
                              :placeholder="$t(locale.paymentTerms)"
                              :options="termsOfPayment"
                              v-model="singleCustomer.terms_of_payment"
                              @option:selected="computeSelect($event, 'terms_of_payment')"
                              class="resource-selector select-size-sm d-inline-block w-full"
                          />
                        </b-form-group>
                      </div>
                      <div>
                        <b-form-group
                            :label="$t(locale.deliveryTerms)"
                            label-for="input-1"
                        >
                          <v-select
                              :placeholder="$t(locale.deliveryTerms)"
                              :options="deliveryTerms"
                              v-model="singleCustomer.terms_of_delivery"
                              @option:selected="computeSelect($event, 'terms_of_delivery')"
                              class="resource-selector select-size-sm d-inline-block w-full"
                          />
                        </b-form-group>
                      </div>
                      <div>
                        <b-form-group
                            :label="$t(locale.deliveryMethod)"
                            label-for="input-1"
                        >
                          <v-select
                              :placeholder="$t(locale.deliveryMethod)"
                              :options="waysOfDelivery"
                              v-model="singleCustomer.way_of_delivery"
                              @option:selected="computeSelect($event, 'way_of_delivery')"
                              class="resource-selector select-size-sm d-inline-block w-full"
                          />
                        </b-form-group>
                      </div>
                      <!-- <div>
                          <b-form-group
                                  :label="$t(locale.interestBilling)"
                                  label-for="input-1"
                              >
                          <LittleTabs
                              :tabs="[
                                  {
                                      label: 'CustomersCardEdit.yes',
                                      active: true,
                                      value: true,
                                  },
                                  {
                                      label: 'CustomersCardEdit.no',
                                      active: false,
                                      value: false,
                                  },
                              ]"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                              v-model="singleCustomer.type"
                              :defaultValue="true"
                          />
                          </b-form-group>
                      </div> -->
                    </div>
                  </b-col>

                  <b-col cols="12" lg="3" v-if="hidden_fields.price_list">
                    <p
                        class="h4 mb-2"
                        v-text="$t(locale.invoicing)"
                    />
                    <div class="flex flex-col">
                      <div class="flex flex-col md:flex-row md:justify-between gap-2">
                        <div class="w-full">
                          <b-form-group
                              :label="$t(locale.priceList)"
                              label-for="input-1"
                          >
                            <v-select
                                :placeholder="$t(locale.priceList)"
                                :options="priceLists"
                                v-model="singleCustomer.price_list"
                                @option:selected="computeSelect($event, 'price_list')"
                                class="resource-selector select-size-sm d-inline-block w-full"
                            />
                          </b-form-group>
                        </div>
                        <div class="w-full">
                          <b-form-group
                              :label="$t(locale.currency)"
                              label-for="input-1"
                          >
                            <v-select
                                :placeholder="$t(locale.currency)"
                                :options="currencies"
                                v-model="singleCustomer.currency"
                                @option:selected="computeSelect($event, 'currency')"
                                class="resource-selector select-size-sm d-inline-block w-full"
                            />
                          </b-form-group>
                        </div>
                      </div>
                      <div>
                        <b-form-group
                            :label="$t(locale.invoiceDiscount)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleCustomer.invoice_discount"
                              type="number"
                              min="0"
                              :placeholder="$t(locale.invoiceDiscount)"
                          />
                        </b-form-group>
                      </div>
                      <!-- <div class="flex flex-col md:flex-row md:justify-between gap-2">
                          <div class="w-full">
                              <b-form-group
                                      :label="$t(locale.invoiceCharge)"
                                      label-for="input-1"
                                  >
                                  <b-form-input
                                      size="sm"
                                      v-model="singleCustomer.dd"
                                      :placeholder="$t(locale.invoiceCharge)"
                                  />
                              </b-form-group>
                          </div>
                          <div class="w-full">
                              <b-form-group
                                      :label="$t(locale.shippingCharge)"
                                      label-for="input-1"
                                  >
                                  <b-form-input
                                      size="sm"
                                      v-model="singleCustomer.dd"
                                      :placeholder="$t(locale.shippingCharge)"
                                  />
                              </b-form-group>
                          </div>
                      </div> -->
                      <div>
                        <b-form-group
                            :label="$t(locale.priceVat)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                        {
                                                            label: 'CustomersCardEdit.yes',
                                                            active: true,
                                                            value: true,
                                                        },
                                                        {
                                                            label: 'CustomersCardEdit.no',
                                                            active: false,
                                                            value: false,
                                                        },
                                                    ]"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                              v-model="singleCustomer.show_price_vat_included"
                              :defaultValue="true"
                          />
                        </b-form-group>
                      </div>
                    </div>
                  </b-col>

                  <b-col cols="12" lg="3" v-if="hidden_fields.our_reference">
                    <p
                        class="h4 mb-2"
                        v-text="$t(locale.reference)"
                    />
                    <div class="flex flex-col">
                      <div>
                        <b-form-group
                            :label="$t(locale.ourReference)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleCustomer.our_reference"
                              :placeholder="$t(locale.ourReference)"
                          />
                        </b-form-group>
                      </div>
                      <div>
                        <b-form-group
                            :label="$t(locale.yourReference)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleCustomer.your_reference"
                              :placeholder="$t(locale.yourReference)"
                          />
                        </b-form-group>
                      </div>
                      <!-- <div>
                          <b-form-group
                                  :label="$t(locale.customerManage)"
                                  label-for="input-1"
                              >
                              <b-form-input
                                  size="sm"
                                  v-model="singleCustomer.dd"
                                  :placeholder="$t(locale.customerManage)"
                              />
                          </b-form-group>
                      </div> -->
                      <div>
                        <b-form-group
                            :label="$t(locale.externalReference)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleCustomer.external_reference"
                              :placeholder="$t(locale.externalReference)"
                          />
                        </b-form-group>
                      </div>
                    </div>
                  </b-col>

                  <b-col cols="12" lg="3" v-if="hidden_fields.www">
                    <p
                        class="h4 mb-2"
                        v-text="$t(locale.accounting)"
                    />
                    <div class="flex flex-col">
                      <div>
                        <b-form-group
                            :label="$t(locale.invoiceURL)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleCustomer.www"
                              :placeholder="$t(locale.invoiceURL)"
                          />
                        </b-form-group>
                      </div>

                      <div>
                        <b-form-group
                            :label="$t(locale.typeOfVat)"
                            label-for="input-1"
                        >
                          <v-select
                              :placeholder="$t(locale.currency)"
                              :options="[
                                                                {
                                                                    label : 'SE',
                                                                    value : 'SEVAT'
                                                                },
                                                                {
                                                                    label : 'SE reverse Vat',
                                                                    value : 'SEREVERSEDVAT'
                                                                },
                                                                {
                                                                    label : 'EU reverse Vat',
                                                                    value : 'EUREVERSEDVAT'
                                                                },
                                                                {
                                                                    label : 'Subject to EU VAT',
                                                                    value : 'EUVAT'
                                                                },
                                                                {
                                                                    label : 'Export',
                                                                    value : 'EXPORT'
                                                                },
                                                            ]"
                              v-model="singleCustomer.vat_type"
                              @option:selected="computeSelect($event, 'vat_type')"
                              class="resource-selector select-size-sm d-inline-block w-full"
                          />
                        </b-form-group>
                      </div>

                      <div class="flex flex-col md:flex-row md:justify-between gap-2">
                        <div class="w-full">
                          <b-form-group
                              :label="$t(locale.salesAccount)"
                              label-for="input-1"
                          >
                            <v-select class="w-full select-size-sm" v-model="singleCustomer.sales_account"
                                      label="number" @option:selected="changeAccounts($event, 'sales_account')"
                                      :filterable="false" :options="accountResults" @search="searchAccounts">
                              <template slot="no-options">
                                Sök...
                              </template>
                              <template slot="option" slot-scope="option">
                                <div class="d-center">
                                  {{ option.number }}
                                </div>
                              </template>
                              <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                  {{ option.number }}
                                </div>
                              </template>
                            </v-select>
                          </b-form-group>
                        </div>
                        <div class="w-full">
                          <b-form-group
                              :label="$t(locale.vatNumber)"
                              label-for="input-1"
                          >
                            <b-form-input
                                size="sm"
                                v-model="singleCustomer.vat_number"
                                :placeholder="$t(locale.vatNumber)"
                            />
                          </b-form-group>
                        </div>
                      </div>

                      <div class="flex flex-col md:flex-row md:justify-between gap-2">
                        <div class="w-full">
                          <b-form-group
                              :label="$t(locale.costCentre)"
                              label-for="input-1"
                          >
                            <v-select
                                :placeholder="$t(locale.costCenter)"
                                :options="costCenter"
                                v-model="singleCustomer.cost_center"
                                @option:selected="computeSelect($event, 'cost_center')"
                                class="resource-selector select-size-sm d-inline-block w-full"
                            />
                          </b-form-group>
                        </div>
                        <div class="w-full">
                          <b-form-group
                              :label="$t(locale.project)"
                              label-for="input-1"
                          >
                            <v-select
                                :placeholder="$t(locale.project)"
                                :options="projects"
                                v-model="singleCustomer.project"
                                @option:selected="computeSelect($event, 'project')"
                                class="resource-selector select-size-sm d-inline-block w-full"
                            />
                          </b-form-group>
                        </div>
                      </div>

                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </SecondaryCard>
          </b-col>
          <b-col cols="12" lg="8">
            <SecondaryCard :title="$t(locale.einvoiceDetail)" class="mt-2">
              <b-container fluid>
                <b-row>
                  <b-col cols="12" lg="4" >
                    <div class="flex flex-col">
                      <p
                          class="h4 mb-2"
                          v-text="$t(locale.edocument)"
                      />
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.deliveryType)"
                            label-for="input-1"
                            v-if="singleCustomer.default_delivery_types"
                        >
                          <LittleTabs
                              :tabs="[
                                                            {
                                                                label: 'InvoiceCardCustomerEdit.print',
                                                                active: true,
                                                                value: 'PRINT',
                                                            },
                                                            {
                                                                label: 'CustomersCardEdit.firstForm.email',
                                                                active: false,
                                                                value: 'EMAIL',
                                                            },
                                                            {
                                                                label: 'InvoiceCardCustomerEdit.einvoice',
                                                                active: false,
                                                                value: 'ELECTRONICINVOICE',
                                                            },
                                                        ]"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                              v-model="singleCustomer.default_delivery_types.invoice"
                              :defaultValue="true"
                          />
                        </b-form-group>
                      </div>
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.email)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleCustomer.email_invoice"
                              :placeholder="$t(locale.email)"
                          />
                        </b-form-group>
                      </div>

                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.confidentialCopy)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleCustomer.email_invoice_bcc"
                              :placeholder="$t(locale.confidentialCopy)"
                          />
                        </b-form-group>
                      </div>

                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.glnNumber)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleCustomer.gln"
                              :placeholder="$t(locale.glnNumber)"
                          />
                        </b-form-group>
                      </div>

                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.glnNumberDelivery)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleCustomer.gln_delivery"
                              :placeholder="$t(locale.glnNumberDelivery)"
                          />
                        </b-form-group>
                      </div>
                    </div>
                  </b-col>

                  <b-col cols="12" lg="4">
                    <div class="flex flex-col">
                      <p
                          class="h4 mb-2"
                          v-text="$t(locale.quote)"
                      />
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.deliveryType)"
                            label-for="input-1"
                            v-if="singleCustomer.default_delivery_types"
                        >
                          <LittleTabs
                              :tabs="[
                                                            {
                                                                label: 'InvoiceCardCustomerEdit.print',
                                                                active: true,
                                                                value: 'PRINT',
                                                            },
                                                            {
                                                                label: 'CustomersCardEdit.firstForm.email',
                                                                active: false,
                                                                value: 'EMAIL',
                                                            },
                                                        ]"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                              v-model="singleCustomer.default_delivery_types.offer"
                              :defaultValue="true"
                          />
                        </b-form-group>
                      </div>
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.email)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleCustomer.email_offer"
                              :placeholder="$t(locale.email)"
                          />
                        </b-form-group>
                      </div>

                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.confidentialCopy)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleCustomer.email_offer_bcc"
                              :placeholder="$t(locale.confidentialCopy)"
                          />
                        </b-form-group>
                      </div>

                      <!-- <div class="w-full">
                          <b-form-group
                                  :label="$t(locale.glnNumber)"
                                  label-for="input-1"
                              >
                              <b-form-input
                                  size="sm"
                                  v-model="singleCustomer.dd"
                                  :placeholder="$t(locale.glnNumber)"
                              />
                          </b-form-group>
                      </div>-->

                      <div class="w-full">
                        <b-form-group
                            :label="'Invoice remarks'"
                            label-for="input-1"
                        >
                          <b-form-textarea
                              v-model="singleCustomer.invoice_remark"
                              :placeholder="$t(locale.invoiceText)"
                          />
                        </b-form-group>
                      </div>
                    </div>
                  </b-col>

                  <b-col cols="12" lg="4">
                    <div class="flex flex-col">
                      <p
                          class="h4 mb-2"
                          v-text="$t(locale.order)"
                      />
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.deliveryType)"
                            label-for="input-1"
                            v-if="singleCustomer.default_delivery_types"
                        >
                          <LittleTabs
                              :tabs="[
                                                            {
                                                                label: 'InvoiceCardCustomerEdit.print',
                                                                active: true,
                                                                value: 'PRINT',
                                                            },
                                                            {
                                                                label: 'CustomersCardEdit.firstForm.email',
                                                                active: false,
                                                                value: 'EMAIL',
                                                            },
                                                        ]"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                              v-model="singleCustomer.default_delivery_types.order"
                              :defaultValue="true"
                          />
                        </b-form-group>
                      </div>
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.email)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleCustomer.email_order"
                              :placeholder="$t(locale.email)"
                          />
                        </b-form-group>
                      </div>

                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.confidentialCopy)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleCustomer.email_order_bcc"
                              :placeholder="$t(locale.confidentialCopy)"
                          />
                        </b-form-group>
                      </div>

                      <!-- <div class="w-full">
                          <b-form-group
                                  :label="$t(locale.glnNumber)"
                                  label-for="input-1"
                              >
                              <b-form-input
                                  size="sm"
                                  v-model="singleCustomer.dd"
                                  :placeholder="$t(locale.glnNumber)"
                              />
                          </b-form-group>
                      </div> -->
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </SecondaryCard>
          </b-col>
          <b-col cols="12" lg="4">
            <SecondaryCard :title="$t(locale.template)" class="mt-2">
              <b-container fluid>
                <div class="flex flex-col" v-if="singleCustomer.default_templates">
                  <p
                      class="h4 mb-2"
                      v-text="$t(locale.preselcted)"
                  />
                  <div class="w-full">
                    <b-form-group
                        :label="$t(locale.printOutTemplateInvoice)"
                        label-for="input-1"
                    >
                      <v-select
                          :placeholder="$t(locale.printOutTemplateInvoice)"
                          :options="printTemplateInvoice"
                          v-model="singleCustomer.default_templates.invoice"
                          @option:selected="computeSelectTemplate($event, 'invoice')"
                          class="resource-selector select-size-sm d-inline-block w-full"
                      >
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.label + ' ' + option.value }}
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </div>
                  <div class="w-full">
                    <b-form-group
                        :label="$t(locale.printOutTemplateQuote)"
                        label-for="input-1"
                    >
                      <v-select
                          :placeholder="$t(locale.printOutTemplateQuote)"
                          :options="printTemplateOffer"
                          v-model="singleCustomer.default_templates.offer"
                          @option:selected="computeSelectTemplate($event, 'offer')"
                          class="resource-selector select-size-sm d-inline-block w-full"
                      >
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.label + ' ' + option.value }}
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </div>
                  <div class="w-full">
                    <b-form-group
                        :label="$t(locale.printOutTemplateOrder)"
                        label-for="input-1"
                    >
                      <v-select
                          :placeholder="$t(locale.printOutTemplateOrder)"
                          :options="printTemplateOrder"
                          v-model="singleCustomer.default_templates.order"
                          @option:selected="computeSelectTemplate($event, 'order')"
                          class="resource-selector select-size-sm d-inline-block w-full"
                      >
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.label + ' ' + option.value }}
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </div>
                  <div class="w-full">
                    <b-form-group
                        :label="$t(locale.printOutTemplateCash)"
                        label-for="input-1"
                    >
                      <v-select
                          :placeholder="$t(locale.printOutTemplateCash)"
                          :options="printTemplateCashInvoice"
                          v-model="singleCustomer.default_templates.cash_invoice"
                          @option:selected="computeSelectTemplate($event, 'cash_invoice')"
                          class="resource-selector select-size-sm d-inline-block w-full"
                      >
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.label + ' ' + option.value }}
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </div>
                </div>
              </b-container>
            </SecondaryCard>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-col cols="12">
      <b-row align-h="between" class="smallGap mt-1">
        <b-col cols="12" lg="3">

        </b-col>
        <b-col
            cols="12"
            lg="4"
            class="d-flex justify-content-between justify-lg-end"
        >
          <div class="">
            <b-button
                block
                variant="outline-danger"
                @click="$router.go(-1)"
                v-text="$t(locale.cancel)"
            />
          </div>
          <div class="">
            <b-button
                @click="createCustomerInfo"
                block
                variant="primary"
                v-text="processing ? $t('Message.loading') : $t(locale.createCustomer)"
            />
          </div>
        </b-col>
      </b-row>
    </b-col>
    <Modal

        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </div>
</template>

<script>
import basic from '@/@core/components/editCustomer/basic.vue';
import invoice from '@/@core/components/editCustomer/invoice.vue';
import vSelect from 'vue-select';
import debounce from 'lodash.debounce'
import {ValidationObserver} from 'vee-validate';
import {getCountries} from './constants/countries'
import useAppConfig from '@core/app-config/useAppConfig';
import {CUSTOMER_DATA} from './constants/customer'
import accessDenied from "@core/components/permission/accessDenied";
import PAGES from "@/services/helpers/constant";
import {mapGetters} from "vuex";

export default {
  name: 'EditCustomerViews',
  components: {basic, invoice, ValidationObserver, vSelect, accessDenied},

  data() {
    return {
      hidden_fields: {...JSON.parse(JSON.stringify(PAGES['accounting_data']['customers']))},
      config: useAppConfig(),
      tabSwitch: 1,
      singleCustomer: {...CUSTOMER_DATA},
      sendingModalAction: false,
      processing: false,
      countries: [...getCountries()],
      currentComponent: 'basic',
      tabs: [
        {
          label: 'CustomersCardEdit.basic',
          component: 'basic',
          active: true,
        },
        {
          label: 'CustomersCardEdit.invoice',
          component: 'invoice',
          active: false,
        },
      ],
      locale: {
        tabs: 'CustomersCard',
        customerDetails: 'CustomersCardEdit.customerDetail',
        customerNumber: 'CustomersCardEdit.firstForm.custnumber',
        customerType: 'CustomersCardEdit.firstForm.customerType',
        company: 'CustomersCardEdit.company',
        private: 'CustomersCardEdit.private',
        organizationLabel: 'CustomersCardEdit.firstForm.organisationLabel',
        active: 'CustomersCardEdit.firstForm.active',
        name: 'CustomersCardEdit.firstForm.name',
        phone1: 'CustomersCardEdit.firstForm.phone1',
        phone2: 'CustomersCardEdit.firstForm.phone2',
        email: 'CustomersCardEdit.firstForm.email',
        url: 'CustomersCardEdit.firstForm.url',
        address1: 'CustomersCardEdit.firstForm.address1',
        address2: 'CustomersCardEdit.firstForm.address2',
        urlFirst: 'CustomersCardEdit.firstForm.url',
        fax: 'CustomersCardEdit.firstForm.fax',
        zipCodeFirst: 'CustomersCardEdit.firstForm.zipCode',
        cityFirst: 'CustomersCardEdit.firstForm.city',
        country: 'CustomersCardEdit.firstForm.country',
        deliverAddress: 'CustomersCardEdit.deliverAddress',
        name2: 'CustomersCardEdit.secondForm.name',
        phoneSecond1: 'CustomersCardEdit.secondForm.phone1',
        phoneSecond2: 'CustomersCardEdit.secondForm.phone2',
        invoice1: 'CustomersCardEdit.secondForm.invoice1',
        invoice2: 'CustomersCardEdit.secondForm.invoice2',
        zipCode: 'CustomersCardEdit.secondForm.zipCode',
        city2: 'CustomersCardEdit.secondForm.city',
        visitingAddress: 'CustomersCardEdit.visitingAddress',
        name3: 'CustomersCardEdit.thirdForm.name',
        phone13: 'CustomersCardEdit.thirdForm.phone1',
        phone23: 'CustomersCardEdit.thirdForm.phone2',
        address13: 'CustomersCardEdit.thirdForm.address1',
        address23: 'CustomersCardEdit.thirdForm.address2',
        zipCode3: 'CustomersCardEdit.thirdForm.zipCode',
        city3: 'CustomersCardEdit.thirdForm.city',
        memberDetails: 'CustomersCardEdit.memberDetails',
        sni: 'CustomersCardEdit.forthForm.sni',
        cfar: 'CustomersCardEdit.forthForm.cfar',
        shopId: 'CustomersCardEdit.forthForm.shopId',
        notes: 'CustomersCardEdit.forthForm.notes',

        invoiceDetails: 'CustomersCardEdit.invoice',

        paymentLabel: 'InvoiceCardCustomerEdit.paymentLabel',
        paymentTerms: 'InvoiceCardCustomerEdit.paymentTerms',
        deliveryTerms: 'InvoiceCardCustomerEdit.deliveryTerms',
        deliveryMethod: 'InvoiceCardCustomerEdit.deliveryMethod',
        interestBilling: 'InvoiceCardCustomerEdit.interestBilling',
        invoicing: 'InvoiceCardCustomerEdit.invoicing',
        priceList: 'InvoiceCardCustomerEdit.priceList',
        currency: 'InvoiceCardCustomerEdit.currency',
        invoiceDiscount: 'InvoiceCardCustomerEdit.invoiceDiscount',
        invoiceCharge: 'InvoiceCardCustomerEdit.invoiceCharge',
        shippingCharge: 'InvoiceCardCustomerEdit.shippingCharge',
        priceVat: 'InvoiceCardCustomerEdit.priceVat',
        reference: 'InvoiceCardCustomerEdit.reference',
        ourReference: 'InvoiceCardCustomerEdit.ourReference',
        yourReference: 'InvoiceCardCustomerEdit.yourReference',
        customerManage: 'InvoiceCardCustomerEdit.customerManage',
        externalReference: 'InvoiceCardCustomerEdit.externalReference',
        accounting: 'InvoiceCardCustomerEdit.accounting',
        invoiceURL: 'InvoiceCardCustomerEdit.url',
        typeOfVat: 'InvoiceCardCustomerEdit.typeOfVat',
        salesAccount: 'InvoiceCardCustomerEdit.salesAccount',
        vatNumber: 'InvoiceCardCustomerEdit.vatNumber',
        costCentre: 'InvoiceCardCustomerEdit.costCentre',
        project: 'InvoiceCardCustomerEdit.project',
        einvoiceDetail: 'InvoiceCardCustomerEdit.einvoiceDetail',
        edocument: 'InvoiceCardCustomerEdit.edocument',
        deliveryType: 'InvoiceCardCustomerEdit.deliveryType',
        print: 'InvoiceCardCustomerEdit.print',
        confidentialCopy: 'InvoiceCardCustomerEdit.confidentialCopy',
        glnNumber: 'InvoiceCardCustomerEdit.glnNumber',
        glnNumberDelivery: 'InvoiceCardCustomerEdit.glnNumberDelivery',
        quote: 'InvoiceCardCustomerEdit.quote',
        invoiceText: 'InvoiceCardCustomerEdit.invoiceText',
        order: 'InvoiceCardCustomerEdit.order',
        template: 'InvoiceCardCustomerEdit.template',
        preselcted: 'InvoiceCardCustomerEdit.preselcted',
        invoice: 'InvoiceCardCustomerEdit.invoice',
        printOutTemplateInvoice: 'InvoiceCardCustomerEdit.printOutTemplteInvoice',
        printOutTemplateQuote: 'InvoiceCardCustomerEdit.printOutTemplteQoute',
        printOutTemplateOrder: 'InvoiceCardCustomerEdit.printOutTemplteOrder',
        printOutTemplateCash: 'InvoiceCardCustomerEdit.printOutTemplteCash',
        delete: 'CustomersCardEdit.dltBtn',
        cancel: 'CustomersCardEdit.cancel',
        createCustomer: 'CustomersCardEdit.createCustomer',
      },
      waysOfDelivery: [],
      deliveryTerms: [],
      termsOfPayment: [],
      printTemplates: [],
      priceLists: [],
      currencies: [],
      costCenter: [],
      projects: [],
      accountResults: [],
      printTemplateInvoice: [],
      printTemplateOffer: [],
      printTemplateOrder: [],
      printTemplateCashInvoice: [],
    };
  },
  computed: {
    message() {
       return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    features() {
      if (this.GET_LOCALE === '') {
        return this.swedishLang ? 'features' : 'features';
      } else return this.GET_LOCALE === 'sv' ? 'features' : 'features';
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE:'appConfig/getLocale'
    })
  },
  methods: {
    disabledFields() {
      let hidden_object = this.hiddenResource
      try {
        // this.$useJwt.hiddenResource({method: 'get', URL: `/${hidden_object['id']}/`}, {}).then()
        hidden_object['hidden_fields']['accounting_data']['customers'].map(item => {
          this.hidden_fields[item] = false
        })
      } catch (e) {

      }
    },
    switchTabs(tabIndex) {
      this.tabs.find(item => item.active == true).active = false;

      this.currentComponent = this.tabs[tabIndex].component;
      this.tabSwitch = this.tabs[tabIndex].component === 'invoice' ? 2 : 1

      this.tabs[tabIndex].active = true;
    },
    ordering() {
      this.$useJwt.customCustomers({URL: '?ordering=-1', method: 'get'})
          .then(res => {
            if (res.data.data) {
              const array = res.data.data.map(el => parseInt(el.customer_number))

              this.singleCustomer.customer_number = parseInt(Math.max(...array) + 1)
            } else {
              this.singleCustomer.customer_number = 1
            }
          })
          .catch(err => {
          })
    },
    createCustomerInfo() {
      // this.$refs.form.validate().then(res => {
      // });
      this.processing = true
      const payload = this.singleCustomer
      delete payload['country']
      this.$useJwt.customCustomers({URL: '', method: 'post'}, payload)
          .then(res => {
            this.processing = false
            this.$router.push({name: 'acountingCustomers'})
            this.popupMsg(
                this.$t('Message.Success'),
                res.data[this.message],
                'CheckIcon',
                'success'
            );
          })
          .catch(err => {
            this.processing = false
            if (err.response.data && err.response.data && err.response.data.message) {
              if (err.response.data.message.ErrorInformation) {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data.message.ErrorInformation.message,
                    'AlertTriangleIcon',
                    'danger'
                );
              } else {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data.message,
                    'AlertTriangleIcon',
                    'danger'
                );
              }
            } else {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data[this.message],
                  'AlertTriangleIcon',
                  'danger'
              );
            }
            if (err.response.status === 403) {
              window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
            }
          })
    },
    openAccessDeniedModal() {
      this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
      this.$bvModal.show("modal-access-denied");

    },
    getCustomerInformation(uuid) {
      this.$useJwt.getCustomersByid(uuid).then(res => {
        this.singleCustomer = res.data.data
      });
    },
    async getAllRegisters() {
      const waysOfDelivery = await this.$useJwt.getAllRegisters('wayofdelivery')
      const deliveryTerms = await this.$useJwt.getAllRegisters('termsofdelivery')
      const termsOfPayment = await this.$useJwt.getAllRegisters('termsofpayment')
      const printTemplateInvoice = await this.$useJwt.getAllRegisters('printtemplates?type=invoice')
      const printTemplateOrder = await this.$useJwt.getAllRegisters('printtemplates?type=order')
      const printTemplateOffer = await this.$useJwt.getAllRegisters('printtemplates?type=offer')
      const printTemplateCashInvoice = await this.$useJwt.getAllRegisters('printtemplates?type=cashinvoice')
      const priceLists = await this.$useJwt.getAllRegisters('pricelists')
      const currencies = await this.$useJwt.getAllRegisters('currencies')
      const costcenter = await this.$useJwt.getAllRegisters('costcenters')
      const projects = await this.$useJwt.getAllRegisters('projects')

      this.waysOfDelivery = waysOfDelivery.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.deliveryTerms = deliveryTerms.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.termsOfPayment = termsOfPayment.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.printTemplateInvoice = printTemplateInvoice.data.results.map(el => {
        return {label: el.name, value: el.template, id: el.uuid}
      })
      this.printTemplateOffer = printTemplateOffer.data.results.map(el => {
        return {label: el.name, value: el.template, id: el.uuid}
      })
      this.printTemplateOrder = printTemplateOrder.data.results.map(el => {
        return {label: el.name, value: el.template, id: el.uuid}
      })
      this.printTemplateCashInvoice = printTemplateCashInvoice.data.results.map(el => {
        return {label: el.name, value: el.template, id: el.uuid}
      })
      this.priceLists = priceLists.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.currencies = currencies.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.costCenter = costcenter.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.projects = projects.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
    },
    computeSelect(event, key) {
      this.singleCustomer[key] = event.value
    },
    searchAccounts(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteAccounts(loading, search, this);
      }
    },
    searchRemoteAccounts: debounce(function (loading, search, vm) {
      this.$useJwt.getAllRegisters(`accounts?search=${escape(search)}`)
          .then(res => {
            this.accountResults = res.data.results
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    changeAccounts(account, key) {
      this.singleCustomer[key] = account.number
    },
    computeSelectTemplate(data, key) {
      this.singleCustomer.default_templates[key] = data.value
    },
    computeSelectCountry(data, key) {
      this.singleCustomer[key] = data.label
      this.singleCustomer.country_code = data.value
    }
  },

  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    this.ordering()
    this.getAllRegisters()
    this.disabledFields()
  },
};
</script>

<style lang='scss'>
@import '@core/scss/vue/libs/vue-select.scss';

</style>
